import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { grid, sizes, colors } from 'config/variables';
import { toPx } from 'utils/styles';

import Wrapper from 'components/common/Wrapper';
import Headline from 'components/common/Headline';
import TextWrapper from 'components/common/TextWrapper';
import MailchimpSignup from 'components/common/MailchimpSignup';
import Link from 'components/common/Link';
import BackgroundFixed from 'components/common/Segment/BackgroundFixed';
import CraftMenuMeta from 'components/container/CraftMenuMeta';
import CraftComponentSocials from 'components/container/CraftComponentSocials';

const WIDTH_COLUMN_LEFT = '50%';
const WIDTH_COLUMN_RIGHT = '50%';

export const FooterStyled = styled('footer')({
  backgroundColor: 'white',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
});

export const FooterAreasStyled = styled('div')({
  display: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,

  [media('lg')]: {
    display: 'flex',
  },
});

export const FooterAreaLargeStyled = styled('div')({
  width: '50%',

  [media('xl')]: {
    width: WIDTH_COLUMN_LEFT,
  },
});

export const FooterAreaMediumStyled = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  background: props.theme.footerAreaMediumBackground,

  [media('xl')]: {
    width: WIDTH_COLUMN_RIGHT,
  },
}));

export const FooterAreaMediumContentStyled = styled('div')({
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
});

export const FooterContentStyled = styled(Wrapper)({
  minHeight: 'inherit',
  position: 'relative',
  display: 'flex',
  alignItems: 'initial',

  [media('lg')]: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },

  [media('xs', 'md')]: {
    width: '100%',
  },
});

export const FooterContentColumnsStyled = styled('div')({
  margin: `${toPx(sizes.spacing * 2)} auto`,
  width: `calc(100% - ${toPx(grid.gutter)})`,
  maxWidth: toPx(grid.width.xl),

  [media('md')]: {
    margin: `${toPx(sizes.spacing * 2)} auto`,
    width: `calc(100% - ${toPx(grid.gutter * 2)})`,
    height: '100%',
  },

  [media('lg')]: {
    width: `calc(100% - ${toPx(grid.gutter)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
  },
});

export const FooterContentColumnInnerWrapStyled = styled('div')({
  maxWidth: '100%',
});

export const FooterContentLargeStyled = styled('div')({
  [media('lg')]: {
    paddingTop: sizes.spacing * 2,
    paddingBottom: sizes.spacing,
    paddingRight: sizes.spacing * 2,
    paddingLeft: 0,
    width: '50%',
    display: 'flex',
  },
  [media('xl')]: {
    width: WIDTH_COLUMN_LEFT,
  },
});

export const FooterContentMediumStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  [media('lg')]: {
    width: '50%',
  },

  [media('xl')]: {
    width: WIDTH_COLUMN_RIGHT,
  },
});

export const FooterContentMediumContentStyled = styled('div')({
  position: 'relative',

  [media('md')]: {
    height: '100%',
  },

  [media('lg')]: {
    paddingTop: sizes.spacing * 3,
    paddingBottom: sizes.spacing,
    paddingLeft: sizes.spacing * 2,
    marginTop: 0,
    marginBottom: 0,
  },
});

export const FooterContentSmallStyled = styled('div')(
  {
    display: 'flex',
    justifyContent: 'middle',
    alignContent: 'middle',

    [media('lg')]: {
      height: '20%',
      paddingLeft: sizes.spacing * 6,
      paddingRight: 0,
    },

    [`${FooterContentColumnsStyled}`]: {
      margin: `${toPx(sizes.spacing * 2)} auto`,
      justifyContent: 'center',

      [media('lg')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  (props) => ({
    background: props.theme.footerContentSmallBackground,
  }),
);

export const FooterHeadlineStyled = styled(Headline)({
  marginTop: 6,
  color: colors.blueDark,
  marginBottom: '6px !important',
});

export const FooterContactHeadlineStyled = styled(Headline)({
  color: colors.blueDark,
  marginBottom: sizes.spacing,
});

export const FooterCraftMenuMeta = styled(CraftMenuMeta)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const FooterParagraphStyled = styled(TextWrapper)({
  marginTop: 0,
  color: colors.blueDark,
  marginBottom: 6,

  a: {
    color: colors.magenta,
  },

  p: {
    marginBottom: 0,
    fontSize: 14,
  },
});

export const FooterContactStyled = styled('div')({
  color: colors.blueDark,
});

export const FooterLinkStyled = styled(Link)({
  fontSize: 22,
  textTransform: 'uppercase',
  fontWeight: 600,
  color: colors.red,
  textDecoration: 'none',
  paddingBottom: 4,
  transition: 'all 0.2s linear',
  borderBottom: `4px solid ${colors.red}`,
  marginTop: 24,
  marginBottom: 24,
  display: 'inline-block',

  [media('md')]: {
    fontSize: 26,
  },

  [media('xl')]: {
    fontSize: 30,
  },

  '&:hover': {
    // color: colors.white,
    paddingBottom: 0,
    // marginBottom: 76,
  },
});

export const FooterCraftComponentSocialsStyled = styled(CraftComponentSocials)({
  paddingBottom: 0,
  flexWrap: 'wrap',
  // marginLeft: -18,
  a: {
    color: colors.blueDark,
    boxShadow: 'none',
    width: 36,
    height: 15,
    margin: 0,
    padding: 24,
    border: '1px solid black',
    '&:hover': {
      border: `1px solid ${colors.magenta}`,
    },
  },
});

export const FooterContactAdressStyled = styled('div')({
  fontSize: 12,
  marginBottom: sizes.spacing,
});

export const FooterContactStreetStyled = styled('div')({
  marginBottom: 6,
});

export const FooterContactStreetNameStyled = styled('span')({});

export const FooterContactStreetNumberStyled = styled('span')({
  marginLeft: 4,
});

export const FooterContactPostcodeStyled = styled('span')({});

export const FooterContactCityStyled = styled('span')({
  marginLeft: 4,
});

export const FooterContactLocationStyled = styled('div')({
  marginBottom: 0,
});

export const FooterContactPhoneStyled = styled('div')({
  fontSize: 12,
  marginBottom: 6,
});

export const FooterContactPhoneTitleStyled = styled('strong')({
  minWidth: 72,
  display: 'inline-block',
});

export const FooterContactPhoneLinkStyled = styled(Link)({
  textDecoration: 'none',
});

export const FooterContactEmailStyled = styled('div')({
  fontSize: 12,
  marginBottom: 6,
});

export const FooterContactEmailTitleStyled = styled('strong')({
  minWidth: 72,
  display: 'inline-block',
});

export const FooterContactEmailLinkStyled = styled(Link)({
  textDecoration: 'none',
});

export const FooterMailchimpSignupStyled = styled(MailchimpSignup)({});

export const FooterBackgroundSquaresStyled = styled(BackgroundFixed)({
  backgroundPosition: 'bottom -60vh right 20%',
  backgroundSize: 'auto',

  [media('xl')]: {
    backgroundPosition: 'bottom -40vh right 20%',
  },
});

export const FooterMenuMetaStyled = styled('div')({
  margin: `${toPx(sizes.spacing)} 0 0`,
  a: {
    color: colors.blueDark,
  },

  [media('lg')]: {
    margin: `${toPx(sizes.spacing)} 0 0`,
  },
});
